<template>
  <div class="news-detail center">
    <h1 class="tc title">{{info.title}}</h1>
    <div class="news-sub tc">
      <span>发布时间：{{$helper.formatTime(parseInt(info.pubDate), 'Y-M-D h:m')}}</span><span>供稿：{{info.author || 'ITIE'}}</span>
    </div>
    <div class="activity-content rich-text" v-html="info.content"></div>
    <Divider />
    <a class="file" v-if="info.fileUrl" :href="info.fileUrl" target="_black"><Icon type="md-attach" />附件下载</a>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {}
    }
  },
  created() {
    this.$http.get('/meeting/news/'+this.$route.params.id,{
    }).then(res => {
      if (res.code === 0) {
        this.info = res.data
      } else {
        this.$Message.warning(res.msg)
      }
    }).catch(err => {
      this.$Message.error(err)
    })
    this.$http.put('/meeting/news',{
      id: this.$route.params.id
    }).then(res => {})
  }
}
</script>

<style lang="less" scoped>
.news-detail {
  min-height: calc(100vh - 556px);
  padding-top: 50px;
  .title {
    width: 800px;
    margin: 10px auto;
    line-height: 40px;
  }
  h1 {
    font-size: 25px;
    color: #333;
    font-weight: 600;
    line-height: 60px;
  }
  .news-sub {
    span {
      color: #666;
      margin: 0 20px;
      font-size: 16px;
      font-size: 500;
      line-height: 40px;
    }
  }
  .activity-content {
    margin: 0 auto;
    padding: 20px 10px;
    min-height: 50vh;
    width: 800px;
  }
  .file {
    display: block;
    width: 1000px;
    margin: 0 auto;
    padding-bottom: 40px;
    font-size: 20px;
  }
}
</style>
